// Generated by Framer (c747e62)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["SeqpYTtgF"];

const serializationHash = "framer-udMZf"

const variantClassNames = {SeqpYTtgF: "framer-v-1ymzggp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({careersSVG, height, id, width, ...props}) => { return {...props, D4eyd0e6O: careersSVG ?? props.D4eyd0e6O} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;careersSVG?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, D4eyd0e6O, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "SeqpYTtgF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ymzggp", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"SeqpYTtgF"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1vvkil7"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"DY9Um5nbZ"}><Image background={{alt: "", fit: "fill", sizes: "33px", ...toResponsiveImage(D4eyd0e6O)}} className={"framer-166h7a9"} layoutDependency={layoutDependency} layoutId={"ioMjvJ_Vy"}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-udMZf.framer-1xajmu0, .framer-udMZf .framer-1xajmu0 { display: block; }", ".framer-udMZf.framer-1ymzggp { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-udMZf .framer-1vvkil7 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-udMZf .framer-166h7a9 { flex: none; height: 29px; mix-blend-mode: difference; overflow: hidden; position: relative; width: 33px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-udMZf.framer-1ymzggp, .framer-udMZf .framer-1vvkil7 { gap: 0px; } .framer-udMZf.framer-1ymzggp > *, .framer-udMZf .framer-1vvkil7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-udMZf.framer-1ymzggp > :first-child, .framer-udMZf .framer-1vvkil7 > :first-child { margin-left: 0px; } .framer-udMZf.framer-1ymzggp > :last-child, .framer-udMZf .framer-1vvkil7 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 29
 * @framerIntrinsicWidth 33
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"D4eyd0e6O":"careersSVG"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerQ2Ax69yuU: React.ComponentType<Props> = withCSS(Component, css, "framer-udMZf") as typeof Component;
export default FramerQ2Ax69yuU;

FramerQ2Ax69yuU.displayName = "Careers/Card Icon";

FramerQ2Ax69yuU.defaultProps = {height: 29, width: 33};

addPropertyControls(FramerQ2Ax69yuU, {D4eyd0e6O: {title: "Careers SVG", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerQ2Ax69yuU, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})